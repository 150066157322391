import { ArrowBackIosNew } from '@mui/icons-material';
import { Alert, Box, Button, CircularProgress, IconButton, Link as MuiLink, Snackbar, Table, TableBody, TableCell, TableRow, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { titleize } from 'inflection';
import { Dispatch, FC, MutableRefObject, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, ScrollRestoration, useLoaderData, useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';
import { SimpleConfirmSnackbar, SimpleErrorSnackbar } from './AppointmentContainer';
import { PageFooter } from './components/page-footer';
import { LanguageSwitcher } from './LanguageSwitcher';
import { LocBreadcrumbs } from './Locs';
import { AppointmentData, getProfile, LocationsData, ProfileData } from './profiles';
import { useSmartContext } from './SmartProvider';
import { OrganizationStyling, PractitionerInterface, RecipientInterface } from './types';
import { signOut } from 'supertokens-auth-react/recipe/session';
import { SessionAuth, useSessionContext, useClaimValue } from 'supertokens-auth-react/recipe/session/index.js';
import { ProfileAuth } from './ProfileAuth';


export interface OrganizationContext {
  setError: Dispatch<SetStateAction<string>>;
  setNotify: Dispatch<SetStateAction<string>>;
  setTitle: Dispatch<SetStateAction<string>>;
  fontBody: string;
  fontHeader: string;
  colorPrimary: string;
  colorSecondary: string;
  isMedium: boolean;
  isSmall: boolean;
  isXSmall: boolean;
}

export interface ProfileContext extends ProfileData, OrganizationContext {
  profileId: string;
}

export type ProfileParams = { profileId: string };

// loader: async ( { params } ) => await getProfile( params.profileId ),

export const ProfileContainer: FC = () => {
  const { locations, organization, assets, commonTags, countryCode } = useRouteLoaderData( 'profiles' ) as LocationsData;
  const { client: smartClient } = useSmartContext();
  const { state } = smartClient || {};
  const { tokenResponse } = state || {};
  const { profileId } = useParams<ProfileParams>();
  const [ data, setData ] = useState<ProfileData | undefined>();
  const sessionContext = useSessionContext();
  const [ bypass, setBypass ] = useState( false );
  const navigate = useNavigate();

  useEffect( () => {
    if( sessionContext.loading ) return;
    if( location.pathname.startsWith( '/auth' ) ) {
      setBypass( true );
      return;
    }
    if( sessionContext.doesSessionExist ) return;
    // navigate( `/auth?redirectToPath=${ encodeURIComponent( location.pathname ) }` );
  }, [ sessionContext ] );


  useEffect( () => {
    ( async () => {
      if( sessionContext.loading || !sessionContext.doesSessionExist ) return;
      // if( tokenResponse?.patient ) {
      //   const data = await getPatient( tokenResponse );
      //   if( data?.errors ) {
      //     console.error( data.errors );
      //     return;
      //   }
      //   setData( data );
      //   return;
      // }
      const data = await getProfile( profileId );
      if( data?.errors ) {
        console.error( data.errors );
        return;
      }
      setData( data );
      return;
    } )();
  }, [ smartClient, sessionContext ] );


  const [ title, setTitle ] = useState( '' );
  const [ error, setError ] = useState( '' );
  const [ notify, setNotify ] = useState( '' );

  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );
  const isSmall = useMediaQuery( theme.breakpoints.down( 'md' ) );
  const isMedium = useMediaQuery( theme.breakpoints.down( 'lg' ) );

  async function onSignout() {
    await signOut();
    window.location.href = '/app/profiles/logged-out'; // or to wherever your logic page is
  }


  // const { firstName, lastName: lastInitial } = patient;
  //
  // const patientName = `${ firstName } ${ lastInitial }`;
  // const { addressComponents: { lines: addressLines = [] } = {}, telephone } = location;

  const {
    colorPrimary = theme.palette.primary.main,
    colorSecondary = theme.palette.secondary.main,
    fontBody = 'inherit',
    fontHeader = 'inherit',
  } = ( organization || {} );

  if( !sessionContext.loading && !sessionContext.doesSessionExist && profileId ) {
    return <ProfileAuth profileId={profileId}
      organization={{
        ...organization, setTitle, fontBody, fontHeader, colorPrimary, colorSecondary,
        isXSmall, isSmall, isMedium,
        setError, setNotify,
      }}
    />;
  }

  if( sessionContext.loading || !data ) {
    return <CircularProgress />;
  }


  const { recipient } = data || {};
  const context: ProfileContext = {
    ...data,
    setTitle, fontBody, fontHeader, colorPrimary, colorSecondary,
    isXSmall, isSmall, isMedium,
    setError, setNotify,
  };



  return (
    <SessionAuth >
      <Box
        id='profile'
        sx={{
          padding: { xs: '0 1.5rem' },
          maxWidth: { xs: '100%', md: '960px' },

          marginLeft: 'auto',
          marginRight: 'auto',
          fontSize: { xs: '1rem', md: '1.2rem' },
          backgroundColor: '#f9fafb',
          fontFamily: fontBody,
          '& .MuiListItemText-root .MuiTypography-root': {
            fontFamily: fontBody,
          },

        }}>

        <Helmet>
          <title>Profile - {recipient?.firstName || ''}</title>
          <meta name='description' content={`Profile on ${ recipient?.firstName || '' }`} />
          <link rel='icon' href='/favicon.ico' />
        </Helmet>


        { /* <SeoTags title={pageTitle} organization={organization} logoAsset={logoAsset} /> */}
        <ScrollRestoration />

        <SimpleConfirmSnackbar
          open={!!notify}
          onClose={() => { setNotify( '' ) }}
          message={notify}
        />
        <SimpleErrorSnackbar
          open={!!error}
          onClose={() => { setError( '' ) }}
          message={error}
        />

        <Grid item container direction='row'
          sx={{
            margin: { xs: '0 -1.3em', md: 'inherit' },
            width: { xs: '120%', md: 'inherit' },
            // border: '1px solid blue',
          }}
          justifyContent='space-between'
        >
          <Grid item xs={5} >
            <Typography variant='h1'
              align={isXSmall ? 'center' : 'left'}
              style={{
                fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
                color: colorPrimary,
                // color: 'white',
                width: '100%',
                padding: '0rem 1.0rem',
                fontWeight: 'bold', //'600',
                fontSize: 'medium',
                fontSmooth: 'always',
              }}
              className='translated'
            >
              AIC Analog Concierge &trade;
              { /* transparent button used to match height of Location header bar */}
              <IconButton sx={{ color: 'transparent' }}>
                <ArrowBackIosNew />
              </IconButton>
            </Typography>
          </Grid>
          <Grid xs={4} item container direction='row'
            // justifyContent='space-between'
            columnSpacing={2}
            justifyContent='flex-end'
            wrap='nowrap'
          >
            <Grid item >
              <LanguageSwitcher lang={recipient.lang} />
            </Grid>
            <Grid item >
              <Button
                onClick={onSignout}
              >
                Sign out
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <MuiLink
          href='/locations'
          sx={{
            width: '100%',
            textDecorationLine: 'none',
          }}
        >
          <Typography variant='h1'
            align={isXSmall ? 'center' : 'left'}
            style={{
              fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
              backgroundColor: colorPrimary,
              color: 'white',
              width: '100%',
              padding: '0.5rem 1.0rem',
              fontWeight: 'bold', //'600',
              fontSize: 'x-large',
              fontSmooth: 'always',
            }}
            className='translated'
          >
            {title}
            { /* transparent button used to match height of Location header bar */}
            <IconButton sx={{ color: 'transparent' }}> <ArrowBackIosNew /> </IconButton>
          </Typography>
        </MuiLink>


        <LocBreadcrumbs />

        <Outlet
          context={context}
        />

        <PageFooter {...{ organization }} />

      </Box >

    </SessionAuth >

  );
}

